import React from "react";
import SevenDaysBanner from "../components/SevenDaysBanner";
import Footer from "../components/Footer";

export default () => {
    return (
        <div className="Application is-fullheight">
            <div className="section erklaervideo">
                <div className="columns">
                    <div className="column has-text-centered">
                        <br />
                        <h2 className="title ">
                            Erhalten Sie jetzt einen Einblick in unsere
                            professionellen Abläufe
                        </h2>
                        <br />
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                className="ifrmvideo"
                                width="1268"
                                height="713"
                                src="https://www.youtube.com/embed/tsey55xtfX8"
                                title="MeinAlarm24 - So einfach und unkompliziert können wir Sie absichern!"
                                frameBorder="0"
                                allow="accelerometer; autoplay; 
                      clipboard-write; encrypted-media; gyroscope; 
                    picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column has-text-centered">
                        <a
                            className="button preisrechner-btn preisrechner-btn-primary"
                            href="/preisrechner/"
                        >
                            Jetzt kostenlos Preis berechnen
                        </a>
                    </div>
                </div>
            </div>
            <SevenDaysBanner />
            <Footer />
        </div>
    );
};
